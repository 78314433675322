import { navigate } from 'gatsby';
import _ from 'lodash';
import React, { ChangeEvent, FunctionComponent, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Placeholder from 'react-bootstrap/Placeholder';
import Form from 'react-bootstrap/Form';
import Stack from 'react-bootstrap/Stack';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Carregant } from './Carregant';
import { Opcions, Opció, Periodicitat, usaOpcions } from './Opcions';
import { Estat, usaUsuari } from './Usuari';
import { FabricaFormulari } from './Formulari';

export type Quota = {
  id: string,
  opció: Opció,
  estat: Estat,
  cancelar_el: Date,
  import_pendent?: number,
  secret_per_pagar?: string,
};

const { Formulari, usaFormulari } = FabricaFormulari<{}>();

export const Quotes: FunctionComponent<{}> = () => (
  <Formulari>
    <_Quotes/>
  </Formulari>
);

const _Quotes: FunctionComponent<{}> = () => {
  const { usuari, canviaLaQuota } = usaUsuari();
  const { opcions } = usaOpcions();
  const { posaEnviant } = usaFormulari();

  if (!usuari) return <></>;
  if (opcions === undefined) return <Carregant/>;

  const enCanviarLaQuota = async (event: ChangeEvent<HTMLSelectElement>) => {
    posaEnviant(true);
    const id_opció = event.currentTarget.value;
    if (!id_opció) return;
    const èxit = await canviaLaQuota(id_opció);
    if (èxit && !usuari.número_de_targeta) void navigate('/socis/pagar');
    posaEnviant(false);
  };

  const prohibitCanviarDeQuota = 
    usuari.quota?.estat === 'alta incompleta'
    || (usuari.quota?.estat === 'al corrent de pagament' && !usuari.número_de_targeta);

  return (
    <Stack gap={3}>
      <Form.Group>
        <Form.Label>Quota actual</Form.Label>
        <Form.Select required onChange={enCanviarLaQuota} value={usuari.quota?.opció.id ?? ''} disabled={prohibitCanviarDeQuota}>
          {!usuari.quota && <option value="" disabled>Tria una quota</option>}
          <Opcions opcions={opcions} opcióActual={usuari.quota?.opció}/>
        </Form.Select>
        {usuari.quota && (
          <Form.Text className="text-muted">
            {prohibitCanviarDeQuota
              ? 'Per canviar de quota cal estar al corrent de pagament.'
              : 'Tria’n una altra per canviar-la.'
            }
          </Form.Text>
        )}
      </Form.Group>
      <Form.Group>
        <Form.Label>Pagant amb la targeta número</Form.Label>
        <Row>
          <Col>
            <Form.Control
              style={{ fontFamily: 'PT Mono' }}
              disabled
              value={usuari.número_de_targeta
                      ? `**** **** **** ${usuari.número_de_targeta}`
                      : usuari.quota
                        ? 'no introduïda'
                        : 'pendent de triar quota'
                    }
              />
          </Col>
          {usuari.quota &&
            <Col xs="auto">
              {usuari.número_de_targeta
                ? <Button onClick={() => void navigate('canviar-targeta')}>Canviar-la</Button>
                : <Button onClick={() => void navigate('pagar')}>Introduir-ne una</Button>
              }
              
            </Col>
          }
        </Row>
      </Form.Group>
      <Form.Group>
        <Form.Label>Estat</Form.Label>
        <Form.Control disabled value={usuari.quota ? usuari.quota.estat : 'Pendent de triar quota'}/>
      </Form.Group>
      {/* {quota.cancelar_el
        ? (
            <Form.Group>
              <Form.Label>Pendent de cancel·lar el</Form.Label>
              <Form.Control disabled value={quota.cancelar_el.toLocaleDateString('ca')}/>
            </Form.Group>
            {// TODO: botó "continuar sent soci"
            }
          )
        : <Button>
            Donar-me de baixa
          </Button>} */
      }
    </Stack>
  );
}
