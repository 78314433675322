/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {Quotes} from '../../components/Quotes';
import {Alert} from 'react-bootstrap/Alert';
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h3: "h3",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(Quotes), "\n", React.createElement(_components.p, null, " "), "\n", React.createElement(_components.h3, null, "També pots..."), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "/socis/rebuts"
  }, "Veure els teus rebuts"), "."), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "/canviar-contrasenya"
  }, "Canviar la teva contrasenya"), "."), "\n", React.createElement(_components.h3, null, "Pròximament"), "\n", React.createElement(_components.p, null, "En aquesta mateixa pàgina també us podreu donar de baixa."), "\n", React.createElement(_components.p, null, "Mentrestant, sisplau escriviu a ", React.createElement("a", {
    href: "mailto:cplinaodena@gmail.com"
  }, "cplinaodena@gmail.com"), "."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
